@import "./Component/css/index.css";
@import "./Pages/css/index.css";


.page{
  min-height: 100vh;
}

/* 
.home-button,
.login-button {
  color: whitesmoke;
  border: none;
  cursor: pointer;
  background-color: transparent;
} */

.horizontal-image-list-container {
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
}

.carousel-inner {
  display: inline-flex;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu h4 {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu h4 div:hover {
  background-color: #2a6be6;
  color: whitesmoke;
}
  
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}


@media only screen and (max-width: 600px) {

.App{
  display: flex;
  margin-bottom: 2;
}


.buttons{
  flex-direction: column;
}

.buttons Link{
margin: 2px;
}

  /* Hide scrollbar for Chrome, Safari, and Opera */
  .scroll-container::-webkit-scrollbar {
    width: 10px;
    background: transparent; /* make scrollbar transparent */
  }

  /* Hide scrollbar for IE, Edge, and Firefox */
  .scroll-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  


}