.video-container {
  position: relative;
  width:50%;
  height: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.fullscreen-video {
  position: relative;
  width: 100%;
}

.desktop-video{
  position: relative;
  width: 100%;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px; /* Centers the spinner */
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  z-index: 30;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.remote-playback-button {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 10;
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

.remote-playback-button:hover {
  background-color: #444;
}

.overlay-image {
  position: absolute;
  top: 20%;
  left: 30%;
  z-index: 10;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth fade in/out */
  z-index: 20;
}

.random-suggestion-image{
  width: 150px;
  height: auto;
}

.video-container:hover .controls {
  opacity: 1; /* Show controls on hover */
}

.quality-selector {
  position: absolute;
  top: -100px;
  /* right: 100px; */
  z-index: 10;
  padding: 5px;
  font-size: 16px;
}


.play-pause-button, .fullscreen-button, .seek-bar, .volume-control {
  position: absolute;
  z-index: 20;
  padding: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.play-pause-button {
  color: white;
  bottom: 15px;
  left: 5px;
  background-color: transparent;
}

.seek-bar {
  bottom: 20px;
  left: 45px;
  width: 60%;
}

.volume-control {
  position: absolute;
  bottom: 20px;
  right: -85px;
  width: max-content;
  height: 200px; /* Adjust the height as needed */
  transform: rotate(-90deg); /* Rotate the input */
  transform-origin: bottom left; /* Set the transform origin */
}

.volume-control input {
  appearance: none;
  width: 150px; /* The height will be determined by width because of rotation */
  height: 10px; /* Adjust the thickness of the range bar */
}

.volume-control input::-webkit-slider-thumb {
  appearance: none;
  width: 20px; /* Thumb size */
  height: 20px; /* Thumb size */
  background: #333; /* Thumb color */
  cursor: pointer;
}

.volume-control input::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #333;
  cursor: pointer;
}


.fullscreen-button {
  bottom: 20px;
  right: 20px;
}

.show-controls {
  opacity: 1 !important; /* Make controls visible */
}

.hide-controls {
  opacity: 0 !important; /* Hide controls */
}

@media only screen and (max-width: 600px) {
  .video-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
  }

  .random-suggestion-image{
    width: 100px;
    height: auto;
    overflow: hidden;
  }

  .overlay-image {
    position: absolute;
    top: 2%;
    left: 30%;
    z-index: 10;
  }
  .controls {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth fade in/out */
    z-index: 20;
  }
}