.purge-button{
position: absolute;
display:flex;
top: 5px;
left: 35px;
font-weight: 500;
margin-inline: 50%;
justify-content: center;
align-items:top;
}

.purge-button:hover {
    cursor: pointer;
}