.header {
    background-color: transparent;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }


  
.logo {
    font-size: 1.5rem;
    font-weight: bold;
    background-color: transparent;
    color: whitesmoke;
    border: none;
    cursor: pointer;
  }

.title-bar{
  width: 100%;
}

.titlebar-logo-image{
  width: 80px;
  height: auto;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: bold;
  background-color: transparent;
  color: whitesmoke;
  border: none;
  cursor: pointer;
}

.navigation-panel-mobile{
  display: none;
}


  @media only screen and (max-width: 600px) {

    .header{
        flex-direction: column;
      }

      .logo-text {
        font-size: 1rem;
        font-weight: bold;
        background-color: transparent;
        color: whitesmoke;
        border: none;
        cursor: pointer;
      }

      .titlebar-logo-image{
        width: 50px;
        height: auto;
      }
  }