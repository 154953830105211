@import "./DatabasePage.css";
@import "./Home.css";
@import "./TorInterract.css";
@import "./Login.css";
@import "./Requests.css";
@import "./MainPlayer.css";





.container {
    background:transparent;
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 600px;
  }