.home-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;

  }
  
  .background-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 4px;
    position: absolute;
  }
  
  .train-element {
    max-width: 100%;
    object-fit: cover;
  }

  
  .register-button {
    z-index: 1; /* Set a higher z-index for the Row */
    position: relative; /* Add this to create a stacking context */
    margin-top: 200px; /* Move down to overlap with the images */
    padding: 20px;
    border-radius: 10px;
    height: 100vh;
  }

  .title_text{
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.349);
  }

  
  @media only screen and (max-width: 600px) {
    .background-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 3px;
        position: absolute;
      }

      .title_text{
        padding: 20px;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.411);
      }

      .buttons Link Button{
        width: 50px;
      }

      .header{
        width:100%;
      }
     
  }