body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
html,body{
  background:#1F1F1F
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  background: linear-gradient(135deg, #1c1c1c, #2e3a59, #0b0b2a);
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  /* font-family: 'Helvetica Neue', sans-serif; */
  transition: all 0.3s ease;
}

.vivid-page-background {
  background: linear-gradient(135deg, #1c1c1c, #2e3a59, #0b0b2a);
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  /* font-family: 'Helvetica Neue', sans-serif; */
  transition: all 0.3s ease;
}