.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eee;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  padding: 16px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #eee;
}

.button {
  padding: 8px 16px;
  margin-left: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-danger {
  background-color: #d9534f;
  color: white;
}

.button:hover {
  opacity: 0.8;
}


.page-item {
  padding: 8px 16px;
  margin: 0 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.page-item.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.page-item:hover {
  background-color: #ddd;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination .btn {
  margin: 0 4px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  border: none;
}

.pagination .btn.active {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .pagination .btn {
    margin: 4px;
    padding: 6px 12px;
  }
}
